<template>
    <KCourse loader-class="MBcont" course-id="3" class="MBcont">
        <div class="MBtextcont">
            <h1>Роспись и парик</h1>
            <p>Все необходимые материалы можно <a target=”_blank” href="https://docs.google.com/document/d/1lacXMu9c6NPcr633P6bLJMQQFaJHXVncXYttQcvVyPg/edit?usp=sharing"> найти тут</a></p>
            <h2>1-ый шаг. Определяемся с образом</h2>
            <p class="nomargin"><b>Где искать образ?</b></p>
            <ul>
                <li>Нарисуйте</li>
                <li>Фильмы, сериалы, мультфильмы</li>
                <li>Книги, герои, сказки, легенды</li>
                <li>Природа, животные, насекомые</li>
                <li>Картины художников</li>
                <li>Старые фотографии</li>
                <li>Этника, история костюма</li>
                <li>Лучшие модные дома</li>
                <li><a target=”_blank” href="https://www.pinterest.ru/"></a> Pinterest.ru</li>
            </ul>

            <p class="nomargin"><b>Совмещайте несовместимое</b></p>
            <ul>
                <li>Альбинос и африканка</li>
                <li>Ребенок и викинг</li>
            </ul>
        </div>

        <VideoView video-id="d9cdb23f53264e349b4746fae85ce433"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Определите цветовую гамму</b></p>
            <p>Один главный + два, три дополнительных. <a target=”_blank” href="https://color.romanuke.com/"> В помощь сервис.</a></p>
            <p class="nomargin"><b>Итого, что у вас должно быть готово:</b></p>
            <ol>
                <li>Определитесь с образом и цветовой гаммой (соберите образ, используя картинки, ткани, волосы, кружева, бисер)</li>
                <li>Отшкурьте все детали до идеально гладкой поверхности (сначала используя грубую шкурку, потом финишную + влажный следок)</li>
                <li>Уберите всю карандашную разметку (с помощью шкурки или влажного следка)</li>
                <li>Проклейте шарнироприемники</li>
                <li>Сделайте 1-ую сборку</li>
            </ol>

            <h2>2-ой шаг. Подготовка</h2>
            <h3>2.1 Красим волосы</h3>
        </div>

        <VideoView video-id="e101683d53fd46b9970b2da1f35d0ffe"/>

        <div class="MBtextcont">
            <h3>2.2 Делаем шапочку для волос </h3>
            <p>Только для шарнирок</p>
        </div>

        <VideoView video-id="622d949177614455885306120374f2d1"/>

        <div class="MBtextcont">
            <h2>3-ий шаг. Грунтуем, красим</h2> 
            <h3>3.1 Замешиваем грунт и краску для тела</h3>
        </div>

        <VideoView video-id="d8950f369be6434986e1faa92b2acc46"/>

        <div class="MBtextcont">
            <h3>3.2 Покрываем детали грунтом и краской</h3>
        </div>

        <VideoView video-id="fa3f7ebaca4645baae535ec8472566b9"/>

        <div class="MBtextcont">
            <h2>4-ый шаг. Расписываем</h2>
            <h3>4.0 Подготовка к росписи</h3>
        </div>

        <VideoView video-id="abcbd8f6a71d43429cefe292b036b80e"/>

        <div class="MBtextcont">
            <h3>4.1 Наносим основной тон</h3>
        </div>

        <VideoView video-id="3dac2c47800f42b995175e96a839f063"/>
        
        <div class="MBtextcont">
            <h3>4.2 Расписываем глаза</h3>
        </div>

        <VideoView video-id="97288a18e8ec4e899b1d6745f64edac9"/>
        
        <div class="MBtextcont">
            <h3>4.3 Расписываем детали лица</h3>
        </div>

        <VideoView video-id="57e067fc3eca47dab54ae6f9c8676ce1"/>


        <div class="MBtextcont">
            <h3>4.4 Веснушки, родинки, покрываем лаком</h3>
        </div>
        
        <VideoView video-id="48b5fc8fc0e3418ab2fac2715b3bfede"/>

        <div class="MBtextcont">
            <h3>4.5 Расписываем тело</h3>
        </div>

        <VideoView video-id="289a5fd67ddc4977acf03eb138746960"/>      

        <div class="MBtextcont">
            <h2>5-ый шаг. Делаем парик</h2>
            <h3>5.1 Схемы выкладки волос</h3>
            <p>Cмотрим урок, изучаем, применяем и активно думаем над прической для своей куклы. От вас требуется, чтобы вы поняли какой будет причёска, какие навыки и детали вы бы хотели изучить.</p>
        </div>

        <VideoView video-id="9a12a011d37a46ef88ca62e04b4ef08d"/>      

        <div class="MBtextcont">
             <h3>5.2 Трессы</h3>
        </div>

        <VideoView video-id="b4873bec8b554c87a3787795609f5a17"/>      

        <div class="MBtextcont">
             <h3>5.3 Парик с челкой и макушкой</h3>
             <p>Это последние уроки нашего марафона. И мне немного грустно... Но! Работа еще не закончена, а это значит, что впереди еще много нового и интересного. Хочу пожелать всем нам подольше сохранить в себе эту творческую атмосферу и радость от процесса. Остались лишь детали, но это порой самое вкусное!</p>
        </div>

        <VideoView video-id="149104aedb974174a8173b9608520971"/>      

        <div class="MBtextcont">
             <h3>5.4 Парик с прямым пробором</h3>
        </div>

        <VideoView video-id="0bcc4ab211b34b08971fa7e6e5907523"/>    

        <div class="MBtextcont">
             <h3>5.5 Пятипрядная коса, кудри, реснички</h3>
        </div>

        <VideoView video-id="2375617673ee439ead6b7373b9c4fa87"/>     

        <div class="MBtextcont">
            <h3>5.6 Оформляем прическу</h3>
            <p>И в догонку вам несколько идей, как можно оформить прическу )</p>
        </div>

        <VideoView video-id="b005e1c3e7ca41c7824d88a51fece143"/>     
        <VideoView video-id="81cec4fc8e70411c95bf240ef0ae5b8d"/>     

        <div class="MBtextcont">
            <p>Знания без практики сами по себе ничего не значат, поэтому я очень благодарна всем, кто сделал свою куклу вместе с нами! </p>
            <p>Теперь вы владеете бесценным опытом и вдохновением от собственного результата ) Очень важно именно сейчас не расплескать этот настрой, этот творческий поток и начать двигаться дальше… Исполнить свои мечты. Вполне вероятно, что мы увидимся с вами на выставках как участники, а не как зрители! И ваши куклы приобретут ваш неповторимый авторский стиль!</p>
        </div>
    </KCourse>
</template>

<script>
    import KCourse from "../Widgets/KCourse";
    import VideoView from "../Widgets/VideoView";
    export default {
        components: {
            KCourse,
            VideoView
        }
    }
</script>

<style>
    .MBcont {
        max-width: 1100px;
        margin: 0 auto;
        border-top: 1px solid #cbcbcb;
        padding: 30px;
        min-height: 300px;
        position: relative;
    }

    .MBcont h1 {
        font-size: 35pt;
        line-height: 45pt;
        text-align: left;
        margin: 0 0 20px 0;
        font-weight: normal;
    }
    .MBcont .subtitle {
        position: relative;
        top: -25px;
        font-size: 10pt;
    }

    .MBtextcont {
        max-width: 550px;
        margin: 0 auto 15pt auto;
        font-size: 12pt;
        line-height: 22pt;
    }

    .MBtextcont h1 {
        margin-top: 20pt;
    }

    .MBtextcont h2 {
        font: 600 16pt/20pt "Montserrat", arial, sans-serif;
        text-align: left;
        margin: 40pt 0 15pt 0;
    }

    .MBtextcont ul {
        list-style: disc;
    }

    .MBtextcont ul,
    .MBtextcont ol {
        list-style: disc;
        margin: 0;
        padding-left: 40pt;
    }

    .MBtextcont ul li,
    .MBtextcont ol li {
        margin-bottom: 0pt;
    }

    .MBtextcont p {
        margin-bottom: 15pt;
        margin-top: 0;
    }

    .MBtextcont p.nomargin {
        margin: 0pt;
    }

    .MBback {
        position: absolute;
        top: 0;
        left: 0;
    }

    /* .MBtextcont .a-download {
            padding: 5px 5px 5px 25px;
            background: url('/img/membersh/MB-icons.svg') no-repeat;
            background-size: 20px;
            background-position: 2px -75px;
        } */

    .MBtextcont h3 {
        font: 600 12pt/20pt "Montserrat", arial, sans-serif;
        text-align: left;
        margin: 0;
    }

    .MBtextcont a::after {
        content: "";
        border-top: 1px solid #b23642;
        position: absolute;
        width: 100%;
        left: 0;
        right: 100%;
        bottom: -3px;
        transition: width 0.4s ease-out;
    }

    .MBtextcont a:hover::after {
        width: 0;
    }
</style>